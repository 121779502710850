import React, { Component } from 'react'
import Logo from '../components/logo'
import Title from '../components/title'
import Button from '../components/button'
import Wrapper from "../components/wrapper"

export default class ThanksForRatingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '', // form field
      message: '', // form field
      // isPolicyAccepted: false, // form field

      feedbackMessage: ''
    }

    this.isFormValid = this.isFormValid.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }


  componentDidMount() {
    /**
    * Store email from URL params in state
    */
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get('email');

      this.setState({
        email
      })
    }
  }

  /**
   * Returns bool,
   * every field must be filled in form to be valid.
   */
  isFormValid() {
    return this.state.message !== ''
  }

  handleFormSubmit(e) {
    const API_URL = 'https://us-central1-mindworxacademy.cloudfunctions.net/emailRating'

    e.preventDefault();
    // disable button
    // submitButton.style.backgroundColor = '#cfd7df';
    // submitButton.disabled = true;

    fetch(API_URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Origin': 'https://mindworxacademy.com'
      },
      body: JSON.stringify({
        experience: this.state.message,
        email: this.state.email
      }) // body data type must match "Content-Type" header
    }).then(res => res.json())
      .then(json => {
        console.log("json", json)
        this.setState({ feedbackMessage: 'Thanks for sharing your experience :)' })

      })
      .catch(err => {
        console.error("err", err)
        this.setState({
          feedbackMessage: 'Something went wrong, try submitting again.'
        })
      });
  }

  render() {
    return (
      <Wrapper>
        <Logo type={'black'} style={{ margin: '30px auto', display: 'block' }} />
        <Title>Thanks for rating!</Title>

        <label
          htmlFor="experience"
          style={{ fontSize: '16px', display: 'block', fontWeight: 'normal' }}
        >
          Your experience
        </label>
        <textarea
          id="experience"
          placeholder="Your message"
          required
          onChange={e => this.setState({ message: e.target.value })}
          style={{
            padding: '10px 12px',
            color: '#32325d',
            backgroundColor: 'white',
            border: '1px solid #f1f1f1',
            borderRadius: '4px',
            boxShadow: '0 1px 3px 0 #e6ebf1',
            transition: 'box-shadow 150ms ease',
            display: 'block',
            margin: '16px 0',
            width: '100%',
            fontSize: '16px',
          }}
          rows={3}
        >
        </textarea>
        <Button
          size={'small'}
          style={{ margin: '25px 0 0 0', display: 'block' }}
          onClick={this.handleFormSubmit}
          disabled={!this.isFormValid()}
        >
          Submit
        </Button>
        <div style={{ margin: '30px 0', textAlign: 'center', fontSize: '20px' }}>
          {this.state.feedbackMessage}
        </div>
      </Wrapper>
    )
  }
}

/*
  < div class="sales-page affiliate-page" style = "margin-top:50px" >
    <div class="wysiwyg-content" style="padding:0; margin:0"><!-- HERO HEADING -->
    <div class="container" id="hero">
        <div class="row">
          <div>
            <div class="col-sm-10 col-sm-offset-1">
              <div class="sales-page__section">
                <div class="row row-flex" style="text-align:center">
                  <div class="box_730">
                    <div class="logo-wrapper">
                      <svg class="sico" style="width:80px; height:80px">
                        <title></title>
                        <use xlink:href="/layout/icons.svg#s_logo_part1">
                      </use>
                      <use xlink:href="/layout/icons.svg#s_logo_part2">
                      </use>
                    <g class="blue logo_scroll">
                      <use xlink:href="/layout/icons.svg#s_logo_part1">
                        </use>
                      </g>
                  <g class="red logo_scroll">
                    <use xlink:href="/layout/icons.svg#s_logo_part2">
                        </use>
                      </g>
                    </svg>
            </div>
            <h1 class="text-center" style="margin-top: 40px">Thanks for rating!
                  </h1>
          </div>
        </div>
      </div>
    </div>
        </div >
      </div >
    </div >
  <div class="box_730">
    <p>If you don't mind to share your experience with us, we'll be grateful!
      </p>
    <form action="https://us-central1-mindworxacademy.cloudfunctions.net/emailRating" id="experience-form" method="post">
    <label for="experience" style="font-size:16px; display:block; font-weight: normal">
    Your experience</label>
    <textarea id="experience" rows="4" style="padding: 10px 12px;color: #32325d;background-color: white;border: 1px solid #f1f1f1;border-radius: 4px;box-shadow: 0 1px 3px 0 #e6ebf1;-webkit-transition: box-shadow 150ms ease;transition: box-shadow 150ms ease;display: block; margin: 16px 0; width: 100%; font-size: 16px
        "></textarea>
      <div>
        <button class="btn_red" id="submit-button" type="submit">Submit
          </button>
      </div>
    </form>
    <p id="on-success" style="text-align:center; margin-top: 16px; display:none">Thanks for sharing your experience :)
      </p>
    <p id="on-failure" style="text-align:center; margin-top: 16px; display:none">Something went wrong, try submitting again.
      </p>
  </div>
  </div >
</div >
  <script>
    var form = document.getElementById('experience-form');
    var submitButton = document.getElementById('submit-button');
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var email = urlParams.get('email');
    console.log(email);
  form.onsubmit = function(e) {
      e.preventDefault();
    // disable button
    submitButton.style.backgroundColor = '#cfd7df';
    submitButton.disabled = true;
    var url = form.getAttribute('action');
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
      'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Origin': 'https://mindworxacademy.com'
      },
      body: JSON.stringify({
      experience: document.getElementById('experience').value,
        email: email
      }) // body data type must match "Content-Type" header
    }).then(function(res) {
      document.getElementById('on-success').style.display = 'block';
    })
      .catch(function(err) {
      document.getElementById('on-failure').style.display = 'block';
      // reset submit button
      submitButton.style.backgroundColor = '#FF1A2A';
      submitButton.disabled = false;
    });
  };
</script>
*/
